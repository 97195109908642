'use strict';

import ProductDetailBase from 'org/components/product/ProductDetail';

export default class ProductDetailEMEA extends ProductDetailBase {
    init() {
        super.init();
    }

    getSizeAttributesHtml(product) {
        if (!product.variationAttributes) {
            return '';
        }
        var html = '';
        var attrSelected = 'selectable m-active selected';
        var varitionTab = $('.pdp-open-new-tab', this.$el).attr('href');
        var lengthFlag = $('input[name="lengthFlag"]').val();
        product.variationAttributes.forEach((attributeGroup) => {
            if (attributeGroup.attributeId === 'size') {
                attributeGroup.values.forEach((attribute) => {
                    html += `<li class="js-sizeAttributes" data-url="${attribute.url}">
                    <a href="${varitionTab || ''}" data-size-attr="${attribute.displayValue}" data-attr-value="${attribute.id}" aria-label="size" class="js-size-select ${!attribute.selectable ? 'disabled' : ''} ${attribute.selected ? attrSelected : ''}`;
                    if (lengthFlag === true || lengthFlag === 'true') {
                        html += `">${attribute.displayValue}</a></li>`;
                    } else if (product.custom.gender && product.custom.gender.toLowerCase() === 'unisex') { // size chips values for unisex products
                        html += ` f-unisex_sizechips" title="${attribute.displayValue}">${attribute.displayValue}</a></li>`;
                    } else { // for products with gender other than unisex
                        html += `">${attribute.displayValue}</a></li>`;
                    }
                });
            }
        });
        return html;
    }

    showMoreLessColor() {
        const lastVisibleClassName = 'last-visible';
        const visibleMobileRows = 2;
        const visibleDesktopRows = 2;

        $(this.$el).find('.b-product_attribute.m-color').each((i, el) => {
            const $colorSwatchBlock = $(el);
            const $swatchesListContainer = $colorSwatchBlock.find('.b-swatches_circle');
            const $colorSwatchItems = $colorSwatchBlock.find('.b-swatches_circle-item');
            const $colorSwatchActionCTAs = $colorSwatchBlock.find('.b-color-more_less');
            let initial = true;

            const calculateVisibleSwatches = () => {
                // in order to skip desktop version for mobile view
                if (!$colorSwatchItems.is(':visible')) {
                    return;
                }
                const swatchWidth = Math.ceil($colorSwatchItems.eq(0).width() || 0);
                const cssGap = $swatchesListContainer.css('column-gap') || '';
                const gap = Number(cssGap.replace(/[\D]/g, '')) || 0;
                const swatchesListContainerWidth = Math.floor($swatchesListContainer.width() || 0);
                let leftFreeRowSpace = swatchesListContainerWidth;
                let itemsInTheRow = 0;
                while (leftFreeRowSpace > swatchWidth) {
                    leftFreeRowSpace -= (swatchWidth + gap);
                    itemsInTheRow += 1;
                }

                const visibleRows = $(window).width() < 1025 ? visibleMobileRows : visibleDesktopRows;
                const totalVisibleSwatches = visibleRows * itemsInTheRow;

                $colorSwatchItems.filter(`.${lastVisibleClassName}`).removeClass(lastVisibleClassName);
                if ($colorSwatchItems.length > totalVisibleSwatches) {
                    const lastVisibleIndex = (visibleRows * itemsInTheRow);
                    $colorSwatchItems.eq(lastVisibleIndex).addClass(lastVisibleClassName);

                    if (initial && lastVisibleIndex < $colorSwatchItems.filter('.swiper-slide-activated').eq(0).index()) {
                        $colorSwatchBlock.addClass('show-all');
                        $('body').trigger('product:showMoreFired', {
                            type: 'color',
                            name: 'product: showMoreFired'
                        });
                    }
                    $colorSwatchActionCTAs.removeClass('hide');
                } else {
                    $colorSwatchActionCTAs.addClass('hide');
                }
            };

            $(window).on('resize', () => {
                calculateVisibleSwatches();
            });
            calculateVisibleSwatches();

            $colorSwatchBlock.on('click', '.js-color-show-more', function () {
                $colorSwatchBlock.addClass('show-all');
                initial = false;

                $('body').trigger('product:showMoreFired', {
                    type: 'color',
                    name: 'product: showMoreFired'
                });
            });
            $colorSwatchBlock.on('click', '.js-color-show-less', function () {
                $colorSwatchBlock.removeClass('show-all');
                initial = false;
            });
        });
    }
}
